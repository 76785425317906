import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import AccountCircle from "@material-ui/icons/AccountCircle";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import MenuIcon from "@material-ui/icons/Menu";
import clsx from "clsx";
import { useRouter } from "next/router";
import React, { useContext } from "react";
import UserContext from "../../src/UserContext";
import ListItems from "./SideBar";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    height: 64,
    ...theme.mixins.toolbar,
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: "calc(100vh - 128px)",
  },
  search: {
    alignItems: "center",
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(6),
      width: "auto",
    },
    display: "flex",
    flexDirection: "row",
  },
  searchIcon: {
    height: "100%",
    position: "relative",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingRight: theme.spacing(1),
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(1)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "29ch",
    },
    justifyContent: "left",
  },

  icons: {
    flexGrow: 1,
  },
}));

function getUserType(keycloakState) {
  if (
    keycloakState?.authenticated === true &&
    (JSON.parse(sessionStorage.getItem("userSession"))?.isAdmin ||
      JSON.parse(sessionStorage.getItem("userSession"))?.isChamberUser)
  ) {
    return "authenticated";
  } else {
    return "notauthenticated";
  }
}

export default function Dashboard({ children }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  // const inputValue = " "
  const router = useRouter();
  let { keycloakstate, setKeycloakstate } = useContext(UserContext);
  // const [searchText, setSearchText] = React.useState(inputValue);
  // const [isSearching, setIsSearching] = useState(false)
  // const debouncedSearchTerm = useDebounce(searchText, 500)

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleProfileMenuOpen = async () => {
    await localStorage.removeItem("token");
    await router.push("/");
  };

  const logout = async () => {
    sessionStorage.removeItem("authentication");
    sessionStorage.removeItem("refreshToken");
    sessionStorage.removeItem('userSession');
    sessionStorage.removeItem("roles");
    var currentURL = window.location.protocol + "//" + window.location.host;
    const keycloakJson = (await import(`../../public/keycloak/${process.env.keycloak}`)).default;
    window.location =
      keycloakJson["auth-server-url"] +
      "/realms/" +
      keycloakJson["realm"] +
      "/protocol/openid-connect/logout?redirect_uri=" +
      currentURL;
  };
  const menuId = "primary-search-account-menu";

  /**
   * TODO: Global Search Implementation
   */

  // const fetchPostTypeData = async (searchText) => {
  //   const res = await fetch(
  //     `https://wpchamber.knowledge.gr/wp-json/wp/v2/search?search=${searchText}`
  //   );
  //   if (!res.ok) {
  //     console.error("Error");
  //   }
  //   else {
  //     const dataPostType = await res.json();
  //     return dataPostType;
  //   }
  // };

  // const fetchCategoryData = async (searchText) => {
  //   const res = await fetch(
  //     `https://wpchamber.knowledge.gr/wp-json/wp/v2/categories?search=${searchText}`
  //   );
  //   if (!res.ok) {
  //     // oups! something went wrong
  //     console.error("Error");
  //   }
  //   else {
  //     const dataCategory = await res.json();
  //     return dataCategory;
  //   }
  // };

  // const fetchMediaData = async (searchText) => {
  //   const res = await fetch(
  //     `https://wpchamber.knowledge.gr/wp-json/wp/v2/media?search=${searchText}`
  //   );
  //   if (!res.ok) {
  //     // oups! something went wrong
  //     console.error("Error");
  //   }
  //   else {
  //     const dataMedia = await res.json();
  //     return dataMedia;
  //   }
  // };

  // const fetchData = async (searchText) => {
  //   const postTypeData = await fetchPostTypeData(searchText);
  //   const categoryData = await fetchCategoryData(searchText);
  //   const mediaData = await fetchMediaData(searchText);
  //   const data = postTypeData.concat(categoryData).concat(mediaData);
  //   return data;
  // };

  // useEffect(
  //   () => {
  //     if (debouncedSearchTerm) {
  //       setIsSearching(true);
  //       fetchData(searchText).then(data =>{
  //         setIsSearching(false);
  //         })
  //     }
  //   },
  //   [debouncedSearchTerm]
  // );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            Διαχείριση Περιβάλλοντος Portal
          </Typography>
          {/* <div id="search-bar" className={classes.search}>
            <InputBase
              id="input-text"
              placeholder="Αναζήτηση"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
              onChange={e => setSearchText(e.target.value)}
            />
            <div id="search-icon" className={classes.searchIcon}>
              <SearchIcon />
            </div>
          </div> */}
          <div id="icons" className={classes.icons}></div>
          {/* <IconButton color="inherit">
            <Badge color="secondary"></Badge>
            <NotificationsIcon />
          </IconButton> */}
          {keycloakstate?.authenticated === true ? (
            <>
              <MenuItem onClick={handleProfileMenuOpen}>
                <IconButton
                  aria-label="account of current user"
                  aria-controls="primary-search-account-menu"
                  aria-haspopup="true"
                  className={classes.iconButton}
                >
                  <AccountCircle />
                </IconButton>
                {keycloakstate?.userName}
              </MenuItem>
              <Tooltip
                title="Έξοδος από την εφαρμογή"
                placement="top-start"
                arrow
              >
                <IconButton
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={logout}
                  color="inherit"
                >
                  <ExitToAppIcon />
                </IconButton>
              </Tooltip>
            </>
          ) : (
            <MenuItem onClick={() => router.push("/login")}>
              <IconButton
                aria-label="Σύνδεση/Εγγραφή"
                className={classes.iconButton}
              >
                <ExitToAppIcon />
              </IconButton>
              <p>Σύνδεση/Εγγραφή</p>
            </MenuItem>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        {getUserType(keycloakstate) == "authenticated" ? (

        <ListItems />
        ) : null}

      </Drawer>
      <main id="right-content" className={classes.content}>
        <div id="toolbar" className={classes.toolbar} />
        <div id="content" className={classes.fixedHeight}>
          {children}
        </div>
      </main>
    </div>
  );
}
